import { useState } from "react";
import styles from "../../Styles/modal.module.css";

const Modal = ({
  title,
  description,
  onBtn1Click,
  onBtn2Click,
  state,
}: any) => {
  const deleteRecord = () => {};

  return (
    <>
      {state && (
        <div className={styles.container}>
          <div className={styles.card}>
            <h2>{title}</h2>
            <p>
              Do you really want to delete this record? This process cannot be
              undone.
            </p>
            <div className={styles.btn_container}>
              <button onClick={onBtn1Click}>Cancel</button>
              <button onClick={onBtn2Click}>Delete</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
