import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AppLayout from "../Components/AppLayout/AppLayout";
import Login from "../Pages/Login";
import withRouter from "../Components/withRouter";
import WithTableView from "../GenericPages/WithTableView";
import { blogg } from "../Utils/Data";
import FormLikePage from "../GenericPages/FormLikePage";
import axios from "axios";
import { toast } from "react-hot-toast";

type props = {
  login: boolean;
  loading: boolean;
};

class App extends React.Component<any, props> {
  state = {
    login: false,
    loading: true,
  };

  componentDidMount(): void {
    if (localStorage.getItem("tdmf_token")) {
      // paused - error : replace form with error text
      // suspended - error : replace form with suspended text
      // axios({
      //   url: "https://backend.cftcommerce.com/api/admins/getAdminByToken",
      //   method: "get",
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      //   },
      // })
      //   .then(() => {
      //     this.setState({ login: true, loading: false });
      //   })
      //   .catch(() => {
      //     toast.error("Session Expired, Please Login Again");
      //     this.setState({ login: false, loading: false });
      //     localStorage.clear();
      //   });
      this.setState({ login: true, loading: false });
    } else {
      this.setState({ login: false, loading: false });
    }
  }

  render() {
    return this.state.loading ? (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span className="loader"></span>
      </div>
    ) : (
      <>
        {this.state.login ? (
          <AppLayout>
            <Outlet />
          </AppLayout>
        ) : (
          <Login />
        )}
      </>
    );
  }
}

// account.cftlabs.org
// dashboard.cftlabs.org/mbhnbnnm/

export default withRouter(App);
