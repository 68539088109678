import * as XLSX from "xlsx";

function ObjToExcel(data: any, sheetName: string, fileName: string, accessValue:any) {
	// Create a new workbook
	const workbook = XLSX.utils.book_new();

	// Convert the data to worksheet format
	const worksheet = XLSX.utils.json_to_sheet(
		data.map((val: any, index:any) => {
            let obj:any = {};
            
            obj['S.No.'] = index + 1;

            for(let i = 0; i < accessValue.length; i++){
                obj[accessValue[i].title] = val[accessValue[i].value]
            }

			return obj;
		})
	);

	// Add the worksheet to the workbook
	XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

	// Save the workbook as an Excel File
	XLSX.writeFile(workbook, `${fileName}.xlsx`);
}

export default ObjToExcel;
