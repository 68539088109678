import { useContext, useEffect, useState } from "react";
import callApi from "../../Apis/callApi";
import styles from "../../Styles/Login.module.css";
import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import img1 from "../../Assets/images/commerce.webp";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailSubmited, setEmailSubmited] = useState(false);
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [showError, setShowError] = useState("granted");
  const [resendOtpLoading, setResendOtpLoading] = useState(false);
  const [counter, setCounter] = useState(0);

  const handleOtp = () => {
    if (!email) {
      toast.error("Email is required.");
    } else if (!otp) {
      toast.error("Password is required.");
    } else {
      if (email === "admin@tdmf.com" && otp === "admin@123") {
        localStorage.setItem("tdmf_token", "asfdasfasfasdfasf");
        toast.success("Logged In Successfully.");
        window.location.reload();
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.main}`}>
        <div
          className={`${styles.slide} ${emailSubmited ? styles.forward : ""}`}
        >
          <>
            {/* modal container 1 */}
            <div className={styles.modal_container}>
              <div className={styles.modal}>
                <h1>
                  The Digital Media Feed <br /> Admin
                </h1>
                <div className={styles.fields_container}>
                  <div className={styles.label_and_field}>
                    <span>Email</span>
                    <input
                      type="email"
                      placeholder="Enter email address"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <span style={{ marginTop: "20px" }}>Password</span>
                    <input
                      type="password"
                      placeholder="Enter Password"
                      value={otp}
                      onChange={(e) => {
                        setOtp(e.target.value);
                      }}
                    />
                    <button onClick={handleOtp} disabled={loading}>
                      {loading ? (
                        <span className={styles.loader}></span>
                      ) : (
                        <>Login</>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
      <div
        className={styles.carousel}
        style={{
          width: "100%",
          height: "100%",
          background: "lightblue",
        }}
      ></div>
    </div>
  );
};

export default Login;
