import React, { Component } from "react";
import WithTableView from "../../GenericPages/WithTableView";
import { leads_table_head } from "../../Utils/Data";
import callApi from "../../Apis/callApi";
import axios from "axios";
import { toast } from "react-hot-toast";
import Modal from "../../Components/Modal";
import ObjToExcel from "../../Utils/objToExcel";
import withRouter from "../../Components/withRouter";

class Leads extends Component<any, any> {
  state = {
    contacts: [],
    loading: true,
    inFocus: "",
    modal: false,
  };

  componentDidMount(): void {
    this.getData();
  }

  getData = () => {
    this.setState({ loading: true });
    axios({
      method: "get",
      url: `https://backend.theholidayfeed.com/api/leads`,
    })
      .then((res) => {
        console.log("Leads : ", res.data.leads);
        this.setState({ contacts: [...res.data.leads] });
        this.setState({ loading: false });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        this.setState({ loading: false });
      });
  };

  handleRemarkUpdate = (data: any, remark: any) => {
    axios({
      method: "patch",
      url: `https://backend.theholidayfeed.com/api/leads/${data._id}`,
      data: { remarks: remark },
    })
      .then((res: any) => {
        toast.success("Remark updated successfully.");
        this.getData();
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
      });
  };

  handleDropdownUpdate = (data: any, event: any) => {
    axios({
      method: "patch",
      url: `https://backend.cftcommerce.com/api/contacts/${data._id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token-BO-ADMIN")}`,
      },
      data: { status: event.target.value },
    })
      .then((res: any) => {
        toast.success("Status updated successfully.");
        this.getData();
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
      });
  };

  handleDelete = () => {
    axios({
      method: "delete",
      url: `https://backend.theholidayfeed.com/api/leads/${this.state.inFocus}`,
    })
      .then((res: any) => {
        this.setState({ modal: false, inFocus: "" });
        toast.success("Deleted successfully.");
        this.getData();
      })
      .catch((err: any) => {
        toast.error(err.response.data.message);
      });
  };

  exportExcel = () => {
    ObjToExcel(this.state.contacts, "Leads", "Leads", [
      { title: "Date", value: "date" },
      { title: "Name", value: "firstName" },
      { title: "Mobile Number", value: "mobileNumber" },
      { title: "Email", value: "personalEmail" },
      { title: "Status", value: "status" },
    ]);
  };

  render() {
    return (
      <>
        <Modal
          title={"Delete"}
          description={
            "Do you really want to delete this record? This process cannot be undone."
          }
          onBtn1Click={() => this.setState({ modal: false, inFocus: "" })}
          onBtn2Click={this.handleDelete}
          state={this.state.modal}
        />
        {this.state.loading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "rgba(0,0,0,.3)",
              zIndex: 100000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className="loader"></span>
          </div>
        )}
        <WithTableView
          heading="Leads"
          head={[...leads_table_head]}
          body={this.state.loading ? [] : [...this.state.contacts]}
          body_keys={[
            "firstName",
            "lastName",
            "mobileNumber",
            "personalEmail",
            "remarks",
            "action:delete",
          ]}
          show_btn_1={true}
          btn1Text="Export Excel"
          btn1Type="light_grey"
          onClickHandler={this.exportExcel}
          onRemarksSave={this.handleRemarkUpdate}
          handleDropdownUpdate={this.handleDropdownUpdate}
          onActionClick={(name: any, id: any) => {
            this.setState({ modal: true, inFocus: id });
          }}
          onRowClick={(val: any) => {
            this.props.router.navigate(`/leads/${val._id}`);
          }}
        />
      </>
    );
  }
}

export default withRouter(Leads);
