import { ReactComponent as Blogs } from "../Assets/icons/blogs.svg";
import { ReactComponent as Leads } from "../Assets/icons/leads.svg";
import { ReactComponent as Jobs } from "../Assets/icons/jobs.svg";
import { ReactComponent as Applicants } from "../Assets/icons/Applicants.svg";
import {
  faBlog,
  faUsers,
  faBriefcase,
  faAddressBook,
  faFileInvoice,
  faBox,
  faCubes,
  faPercentage,
  faBellConcierge,
  faBook,
  faUpDown,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";

type ISidebarData = { title: string; icon: any; link: string };
type IBlogTableHead = { title: string };

// fetched form api to show or hide on sidebar
export const sidebar_check = [{ blog: "" }, { contact: "" }, { career: "" }];

export const sidebar_data: ISidebarData[] = [
  { title: "Blogs", icon: "", link: "/blogs" },
];

export const blog_table_head: IBlogTableHead[] = [
  { title: "S.No" },
  { title: "Blog Title" },
  { title: "Status" },
  { title: "" },
];

export const blog_access_keys = ["sno", "title", "status", "action"];

export const leads_table_head = [
  { title: "S.No", value: "s.no" },
  { title: "First Name", value: "name" },
  { title: "Last Name", value: "lastName" },
  { title: "Phone no.", value: "phone" },
  { title: "Email", value: "email" },
  { title: "Remarks", value: "remarks" },
  // Remarks : input box

  // Status: New, open, closed
  { title: "", value: "action" },
  // action : delete,
];

export const jobs_table_head = [
  { title: "S.No", value: "s.no" },
  { title: "Title", value: "title" },
  { title: "Position", value: "title" },
  { title: "Department", value: "department" },
  { title: "Status", value: "status" },
  { title: "Action", value: "action" },
];

export const jobs_table_key = [
  "title",
  "position",
  "department",
  "status",
  "action:delete,archive",
];

export const applicant_table_head = [
  { title: "S.No", value: "s.no" },
  { title: "First Name", value: "firstName" },
  { title: "Email", value: "email" },
  { title: "Phone", value: "phone" },
  { title: "Applied Position", value: "applied_position" },
  { title: "Remarks", value: "" },
  { title: "Application Status", value: "" },
  { title: "Action", value: "action" },
  // delete, view(text)
];

export const compliance_table_head = [
  { title: "S.No." },
  { title: "Title" },
  { title: "Type" },
  { title: "Created At" },
  { title: "Last Updated" },
  { title: "Status" },
  { title: "" },
];

export const products_table_head = [
  { title: "S.NO." },
  { title: "SKU" },
  { title: "Product Name" },
  { title: "Current Stock" },
  { title: "Price" },
  { title: "Created At" },
  { title: "" },
];

export const product_body_keys = [
  "inventory.sku",
  "title",
  "",
  "pricing.price",
  "createdAt",
  "action:delete",
];

export const order_body_keys = [
  "_id",
  "date",

  "location",
  "subLocation",
  "category",
  "subCategory",
  "modeOfPayment",
  "fulfilmentStatus",
];

export const orders_table_head = [
  { title: "S.NO." },
  { title: "Order ID" },
  {
    title: "Booked for",
    up: faArrowUp,
    down: faArrowDown,
    default: "up",
    haveIcon: true,
  },
  // { title: "Customer Id" },
  { title: "Location" },
  { title: "Sub Location" },
  { title: "Category" },
  { title: "Sub Category" },
  { title: "Payment Status" },
  { title: "Order Status" },
  // { title: "Payment Method" },
  // { title: "Payment ID" },
];

export const compliance_data = [
  {
    title: "Terms",
  },
];

export const users_table_head = [
  { title: "S.NO." },
  { title: "First Name" },
  { title: "Last Name" },
  { title: "Email" },
  { title: "Mobile No." },
];

export const users_body_keys = ["firstName", "lastName", "email", "phone"];

export const blogg = [
  {
    path: "/",
    title: "Leads",
    icon: faAddressBook,
    pageType: "table",
    table_head: [
      { title: "S.No", value: "s.no" },
      { title: "Name", value: "name" },
      { title: "Phone no.", value: "phone" },
      { title: "Email", value: "email" },
    ],
    body_keys: ["name", "phone", "email"],
  },
];

export const services_table_head = [
  { title: "S.NO." },
  { title: "Service Title" },
  { title: "Status" },
  { title: "" },
];

export const services_body_keys = ["title", "status:dropdown", "action:delete"];

export const discount_table_head = [
  { title: "S.No" },
  { title: "Discount Title" },
  { title: "Discount Code" },
  { title: "" },
];

export const discount_body_keys = [
  "discountTitle",
  "counponCode",
  "action:delete",
];

export const product_video_table_head = [
  { title: "S.NO" },
  { title: "Video Title" },
  { title: "" },
];

export const product_video_body_keys = ["title", "action:delete"];

export const product_review_head = [
  "select",
  "S.No",
  "Product Name",
  "Category",
  "Sub Category",
  "Type",
];
