import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import "../node_modules/font-awesome/css/font-awesome.css";
// import "font-aweso";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import { Toaster } from "react-hot-toast";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import App from "./App/App";
import Leads from "./Pages/Leads";
import LeadDetailView from "./Pages/Leads/leadDetailView";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const Main = () => {
  const children: any = [];

  children.push({ path: "/", element: <Leads /> });
  children.push({ path: "/leads/:id", element: <LeadDetailView /> });

  const router = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <h1>Errorrrrrrrrr</h1>,
      children: children,
    },
  ]);

  return <RouterProvider router={router} />;
};

root.render(
  <>
    <Toaster />
    <Main />
  </>
);
