import React from "react";
import styles from "./dropBtn.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  text: string;
  isIcon: boolean;
  icon?: any;
  drop_list: any[];
  on_click: any;
};

const DropBtn = (props: Props) => {
  const [drop, setDrop] = React.useState(false);

  return (
    <div className={styles.container} onClick={() => setDrop((prev) => !prev)}>
      {props?.isIcon && <FontAwesomeIcon icon={props.icon} />}
      {props.text}
      {drop && (
        <ul className={styles.drop_container}>
          {props.drop_list.map((val, index) => {
            return (
              <li
                key={index}
                className={styles.drop_item}
                id={val.id}
                onClick={(e: any) => {
                  props.on_click(e.target.id);
                }}
              >
                {val?.icon && <FontAwesomeIcon icon={val.icon} />}
                {val.title}
              </li>
            );
          })}
        </ul>
      )}
      {drop && <div className={styles.cover}></div>}
    </div>
  );
};

export default DropBtn;
